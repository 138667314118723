

















































import { Component, Vue } from "vue-property-decorator";

@Component
export default class LoginPage extends Vue {

  $refs!: {
    form: HTMLFormElement
  }

  public isVisiblePassword = false;
  public valid = true;
  public name = '';
  public email = '';
  public emailRules = [
    (v: string) => !!v || 'E-mail is required',
    (v: string) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
  ];

  validate () {
    this.$refs.form.validate()
  }
}
